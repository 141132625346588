$header-brand-width: 160px;

.header {
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: 40px;
    padding-left: 50px;

    .header__item + .header__item  {
        margin-left: $gutter-width/2;
    }

    @include mq(sm) {
        padding-left: 0;
    }
}

.header__grid {
    @include container($container-width);
    padding-left: $gutter-width/2;
    padding-right: $gutter-width/2;

    @include mq(sm) {
        position: relative;
    }
}

.header__brand {
    display: block;
    width: 160px;
    margin-bottom: $gutter-width;
    margin-left: auto;
    margin-right: auto;

    @include mq(sm) {
        position: absolute;
        top: 0;
        left: $gutter-width/2;
    }

    img {
        display: block;
        width: 100%;
    }
}

.header__options {
    @include mq(sm) {
        position: absolute;
        top: 0;
        right: $gutter-width/2;
        list-style-type: none;
        padding-top: 15px;
    }
}

.header__item {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;

    @include mq(sm) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.header__link {
    font-family: $ff__secondary;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
}
