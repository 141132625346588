.games {

}

.games__row {
    width: 100%;

    @include mq(sm) {
        display: table;
    }
}

.games__col {
    position: relative;
    overflow: hidden;
    text-align: center;

    @include mq(sm) {
        display: table-cell;
        vertical-align: bottom;
    }

    .btn {
       position: absolute;
       bottom: $gutter-width;
       left: 50%;
       transform: translateX(-50%);
    }

}

.games__image {
    width: 100%;
    max-width: 100%;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // z-index: -1;
}
