// ==========================================================================
// Global
// ==========================================================================

html {
    -webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

hr {
	border-top: $border;
	margin: $margin 0;
}
