/* ==========================================================================
   Mixins Buttons
   ========================================================================== */

// /**
//  * Generating button size and variation
//  *
//  *
//  * @example scss - Usage
//  *   .btn {
//  *     @include button-size(10px, 15px, 1em, 6px);
//  *   }
//  *
//  *  .btn--primary {
//  *     @include button-variant(#1A237E, #3F51B5, #3F51B5, #1A237E);
//  *  }
//  *
//  * @example css - Output
//  *   .btn {
//  *     padding: 10px 15px;
//  *     font-size: 1em;
//  *     border-radius: 6px;
//  *   }
//  *
//  *   .btn--primary {
//  *     background-color: #1A237E;
//  *     border-color: #3F51B5;
//  *   }
//  *
//  *   .btn--primary:hover {
//  *     background-color: #3F51B5;
//  *     border-color: #1A237E;
//  *   }
//  */

// the size
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    border-radius: $border-radius;
}

// the look
@mixin button-variant($bg-color, $bg-active, $border-color, $border-active, $effect: null) {
    @if($effect == flatt) {
        position: relative;
        background-color: $bg-color;
        overflow: hidden;
        vertical-align: top;
        z-index: 1;
        transition: color .3s;

        &:after {
            position: absolute;
            top: 90%;
            left: 0;
            width: 100%;
            height: 100%;
            background: $bg-active;
            content: "";
            z-index: -2;
            transition: transform .3s;
        }

        &:hover::after {
            transform: translateY(-80%);
            transition: transform .3s;
        }
    }

    @else if ($effect == globalia) {
        color: $color--globalia;
    }

    @else {
        background-color: $bg-color;
        border-color: $border-color;

        &:hover {
            background-color: $bg-active;
            border-color: $border-active;
        }
    }
}
