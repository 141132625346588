.btn {
    @include button-size(em(14px), em(14px), 1em, 6px);
    display: inline-block;
    background-color: color('greys', 'light');
    color: $white;
    font-weight: 300;
    text-align: center;
    border: 1px solid transparent;
    min-width: 160px;
    outline: 0;

    i {
        margin-left: 5px;
    }
}

.btn--primary {
    @include button-variant($color--primary, $color--secondary, transparent, transparent);
    transition: all 240ms ease;
}

.btn--outline {
    @include button-variant(transparent, $color--primary, $white, $color--primary);
    border-width: 2px;
    transition: all 240ms ease;
}
