@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 100;
	src: url(../fonts/Lato-normal-100.woff) format('woff');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	src: url(../fonts/Lato-normal-300.woff) format('woff');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Lato-normal-400.woff) format('woff');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/Lato-normal-700.woff) format('woff');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 900;
	src: url(../fonts/Lato-normal-900.woff) format('woff');
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Montserrat-normal-400.woff) format('woff');
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/Montserrat-normal-700.woff) format('woff');
}