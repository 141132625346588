// ==========================================================================
// Typography
// ==========================================================================

.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $ff--secondary;
	line-height: $root-line-height;
}

.h1 { font-size: $h1; }
.h2 { font-size: $h2; }
.h3 { font-size: $h3; }
.h4 { font-size: $h4; }
.h5 { font-size: $h5; }
.h6 { font-size: $h6; }

.link {
	color: color(brand,primary);
	text-decoration: none;

    &:hover { text-decoration: underline; }

	&:focus {}
    &:visited {}
	&[disabled] {}
}

.strong {
	font-weight: 600;
}

.italic {
	font-style: italic;
}

.unordered-list,
.ordered-list {
    margin-bottom: $margin/2;
	li {
		position: relative;
        list-style-position: inside;
		&+li {
			margin-top: $margin/2;
		}
        ul, ol {
            margin-top: $margin/2;
        }
        li {
            padding-left: $padding;
        }
	}

}

.ordered-list {
	counter-reset: item;
	li {
        list-style: none;
		&:before {
			content: counters(item, ".")" - ";
			counter-increment: item;
			color: $color--secondary;
		}
		ol {
            counter-reset: item;
		}
	}
}

.address {
	font-size: .875em;
	font-style: normal;
	line-height: 1.5;
}

.typography {
	h1, .h1 { @extend .h1; margin: $margin 0 $margin/2 }
	h2, .h2 { @extend .h2; margin: $margin 0 $margin/2 }
	h3, .h3 { @extend .h3; margin: $margin 0 $margin/2 }
	h4, .h4 { @extend .h4; margin: $margin 0 $margin/2 }
	h5, .h5 { @extend .h5; margin: $margin 0 $margin/2 }
	h6, .h6 { @extend .h6; margin: $margin 0 $margin/2 }
	p {  margin: $margin/2 0; }
	a { @extend .link; }
	strong, b { @extend .strong; }
	em, i { @extend .italic; }
	ul { @extend .unordered-list; }
	ol { @extend .ordered-list; }
	address { @extend .address; }
}
