.social-icons {
	a {
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 100%;
        line-height: 38px;
        border: 2px solid $white;
        transition: all 240ms cubic-bezier(0.215, 0.61, 0.355, 1);

		&:before {
			@extend .fa;
			line-height: inherit;
			font-size: 1.3em;
	    }

		@each $social, $icon in $socials {
			&[href*="#{$social}"] {

				&:before {
					content: map-get($icon, char);
				}

                &:hover {
                    background-color: map-get($icon, color);
                    border-color: map-get($icon, color);
                }
			}
		}
	}
}
