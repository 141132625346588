.footer {
    background-color: #1e1e1e;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;

    .footer__item  + .footer__item  {
        margin-left: $gutter-width/2;
    }

    @include mq(sm) {
        text-align: left;
    }
}

.footer__brand {
    display: block;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $gutter-width;

    @include mq(sm) {
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
    }


}

.footer__options {
    list-style-type: none;
}

.footer__item.social-icons {
    display: inline-block;

    a {
        border-color: #4b4b4b;

        &:hover {
            color: $white;
        }
    }
}

.footer__link {
    color: #4b4b4b;
}
