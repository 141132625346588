.menu {
    background-color: #3b3b3b;
    position: fixed;
    top: 0;
    left: 0;
    text-align: left;
    width: 260px;
    z-index: 200;
    padding-top: 40px;
    padding-left: $gutter-width/2;
    padding-right: $gutter-width/2;
    padding-bottom: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    transform: translateX(-260px);
    transition: all 240ms cubic-bezier(0.215, 0.61, 0.355, 1);

    &.is-open {
        transform: translateX(0);
    }

    @include mq(sm) {
        position: relative;
        background-color: transparent;
        width: 100%;
        display: block;
        width: calc(100% - #{$header-brand-width});
        margin-left: auto;
        padding-top: 27px;
        padding-left: 50px;
        transform: translateX(0);

        .menu__item + .menu__item  {
            margin-left: $gutter-width;
        }
    }
}

.menu__list {
    @include mq(sm) {
        display: flex;
    }
}

.menu__item {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;

    @include mq(sm) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.menu__link {
    font-family: $ff__secondary;
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
    font-weight: 400;
    transition: all 240ms ease;

    &:hover {
        color: $white;
    }

    @include mq(sm) {
        color: rgba($white, 0.8);
    }
}

.menu__svg {
    fill: $white;
    position: absolute;
    top: 40px;
    left: $gutter-width/2;
    cursor: pointer;
    z-index: 20;

    @include mq(sm) {
        display: none;
    }
}

body {
    transition: all 240ms cubic-bezier(0.215, 0.61, 0.355, 1);

    &.has-nav-open {
        // transform: translate3d(260px, 0, 0);
    }
}

/* ==========================================================================
   Overlay
   ========================================================================== */

.gui-overlay {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4;
    visibility: hidden;
    transition-property: background-color;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);

    &.is-visible {
        background-color: rgba(0,0,0,.5);
        visibility: visible;
    }

    // @include mq(md) {
    //     display: none;
    // }
}
