.jumbotron {
    @include viewport-unit(height, 100vh);
    display: table;
    width: 100%;
    background-image: url(../images/jumbotron.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 120px;
    padding-bottom: 20px;
}

.jumbotron__grid {
    display: table-cell;
    vertical-align: middle;
}

.jumbotron__body {
    max-width: 510px;

    .btn {
        margin-bottom: $gutter-width/2;
    }
}

.jumbotron__title {
    font-family: $ff__primary;
    font-size: 48px;
    text-transform: uppercase;
    color: $white;
    line-height: 1;
}

.jumbotron__details {
    color: $white;
    font-weight: 300;
    margin-bottom: $gutter-width;
}
